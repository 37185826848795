import styled from "styled-components";
import classNames from "classnames";


const IconButton: React.FC<IconButtonProps> = ({children, type, onClick, className}) => {

    return (
        <IconButtonStyle type={type || 'button'} className={classNames('btn shadow-none', {[className || '']: className})} onClick={onClick}>
            {children}
        </IconButtonStyle>
    );
};


const IconButtonStyle = styled.button`
  color: #98a6ad;
  font-size: 1.2rem;
  display: inline-block;
  padding: 0 3px;
`;

type IconButtonProps = {
    type?: 'button'|'submit';
    onClick?: (e: React.MouseEvent) => void;
    className?: string;
};

export default IconButton;