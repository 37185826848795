import React from 'react';
import App from './App';
import reportWebVitals from './reportWebVitals';
import axios from "axios";
import {AXIOS_DEFAULTS_TIMEOUT, CTX} from "./core/variables";
import {QueryClient, QueryClientProvider} from "react-query";
import {RecoilRoot} from "recoil";
import {BrowserRouter} from "react-router-dom";
import {ReactQueryDevtools} from "react-query/devtools";
import * as ReactDOMClient from 'react-dom/client';
// import './assets/css/app.min.css'
// import './assets/css/fonts.css'
// import './assets/css/icons.css'
import './assets/global.scss'
// import 'bootstrap/dist/css/bootstrap.css';

axios.defaults.withCredentials = true;
axios.defaults.timeout = Number(AXIOS_DEFAULTS_TIMEOUT);

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
        }
    }
});

const container = document.getElementById('root');

const root = ReactDOMClient.createRoot(container!);


root.render(
  <React.StrictMode>
      <QueryClientProvider client={queryClient}>
          <RecoilRoot>
              <BrowserRouter basename={CTX}>
                  <App />
              </BrowserRouter>
          </RecoilRoot>
          <ReactQueryDevtools initialIsOpen={false}/>
      </QueryClientProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
