import React from "react";
import styled from "styled-components";
import {Button} from "react-bootstrap";
import ReactDOM from "react-dom";
import {useIntl} from "react-intl";

const View40X: React.FC<{
    code: number,
    title: string,
    description: string
}> = (props) => {
    const intl = useIntl()

    const onClickReturnHome = () => {
        window.location.href = '/';
    };

    return ReactDOM.createPortal((
        <ErrorViewWrapper>
            <div className="container">
                <div className="row justify-content-center mt-2">
                    <div className="col-xxl-4 col-lg-5">
                        <div className="card">
                            <div className="card-header pt-4 pb-4 d-flex align-items-center justify-content-center bg-primary">
                                {/*<img src={logo} alt="logo" height="28" className="mr-1"/>*/}
                                <h3 className="m-0 text-white mt-1">
                                    {intl.formatMessage({id:"i00012"})}
                                </h3>
                            </div>

                            <div className="card-body py-4 px-4">
                                <div className="text-center">
                                    <h1 className="text-error">4<i className="mdi mdi-emoticon-sad"/>{props.code%100}</h1>
                                    <h4 className="text-uppercase text-danger mt-3">{props.title}</h4>

                                    <p className="text-start text-muted mt-3 white-space-pre-wrap font-13">{props.description}</p>

                                    <Button variant="info" onClick={onClickReturnHome}>
                                        <i className="mdi mdi-reply me-1"/>
                                        Return Home
                                    </Button>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ErrorViewWrapper>
    ), document.getElementById('portal')!);
};


const ErrorViewWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  
  background-size: cover;
  background-position: center;
`;

export default View40X;