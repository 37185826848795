import {atom} from "recoil"

export const languages: {[key: string]: string} = {
    'ko': '한국어',
    'en': 'English'
};

const i18nState = atom<string>({
    key: 'language',
    default: localStorage.getItem('language') || 'ko'
})

export default i18nState