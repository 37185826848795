import {atom, useRecoilState} from "recoil";
import React, {CSSProperties, useCallback, useMemo} from "react";
import {useIntl} from "react-intl";
import {Button, Modal} from "react-bootstrap";
import classNames from "classnames";

interface MetaDialog {
    isOpen: boolean
    title?: string
    content: string | null
    contentAlign: 'center'|'left'|'right'
    variant: DialogVariant
    customText?: string
    confirmText?: string
    cancelText?: string
    onClickCustom: VoidFunction | null
    onConfirm: VoidFunction | null
    onCancel: VoidFunction | null
    hideConfirm: boolean
    style?: CSSProperties
    closeIntervalId?: any
}

type DialogVariant = "primary" | "success" | "warning" | "danger";

type OpenParams = {
    content: string;
    contentAlign?: 'center'|'left'|'right';
    onConfirm?: VoidFunction;
    title?: string;
    variant?: DialogVariant;
    customText?: string;
    confirmText?: string;
    cancelText?: string;
    onClickCustom?: VoidFunction;
    onCancel?: VoidFunction;
    hideConfirm?: boolean;
    style?: CSSProperties
};

const DialogState = atom<MetaDialog>({
    key: 'dialogStatus',
    default: {} as MetaDialog
})

export const useDialog = () => {
    const [dialog, setDialog] = useRecoilState(DialogState)

    const open = useCallback((params: OpenParams) => {
        if(dialog.closeIntervalId){
            clearTimeout(dialog.closeIntervalId)
            setDialog({...dialog, closeIntervalId: undefined})
        }

        setTimeout(() => {
            setDialog({
                content : params.content,
                contentAlign : params.contentAlign || 'center',
                onConfirm : params.onConfirm || null,
                title : params.title,
                variant : params.variant || "primary",
                customText : params.customText,
                confirmText : params.confirmText,
                cancelText : params.cancelText,
                onCancel : params.onCancel || null,
                onClickCustom : params.onClickCustom || null,
                hideConfirm : params.hideConfirm || false,
                style : params.style,
                isOpen : true
            })
        })
    }, [setDialog, dialog])

    const close = useCallback(() => {
        setDialog({...dialog,
            isOpen: false,
            onConfirm: null,
            onCancel: null,
            closeIntervalId: setTimeout(() => {
                setDialog({
                    ...dialog,
                    isOpen: false,
                    onConfirm: null,
                    onCancel: null,
                    closeIntervalId: undefined,
                    title: undefined,
                    content: null,
                    contentAlign: 'center',
                    variant: 'primary',
                    confirmText: undefined,
                    cancelText: undefined,
                    hideConfirm: false,
                    style: undefined
                })
            }, 250)
        })

    }, [setDialog, dialog])

    return useMemo(() => {
        return {
            open,
            close,
            ...dialog
        }
    }, [open, close, dialog])
}

export const Dialog: React.FC = (() => {
    const dialog = useDialog();
    const intl = useIntl();

    const handleClose = () => {
        if (dialog.onCancel) {
            dialog.onCancel();
        }

        dialog.close();
    };

    const onClickConfirmBtn = (e: React.MouseEvent) => {
        e.preventDefault();

        if (dialog.onConfirm) {
            dialog.onConfirm();
        }

        dialog.close();
    };

    const onClickCustomBtn = (e: React.MouseEvent) => {
        e.preventDefault();

        if (dialog.onClickCustom) {
            dialog.onClickCustom();
        }

        dialog.close();
    };

    return (
        <Modal show={dialog.isOpen} onHide={handleClose} animation={true} role="dialog" centered>
            <Modal.Body style={dialog.style}>
                {/*<Modal.Title as={"h4"} className={`text-${dialog.variant}`}>{dialog.title || intl.formatMessage({id: 'i00177'})}</Modal.Title>*/}
                <h4 className={`text-${dialog.variant} text-center mt-2`}>{dialog.title || intl.formatMessage({id: 'i00011'})}</h4>
                <div className="m-3">
                    <p style={{whiteSpace: 'pre-wrap', textAlign: dialog.contentAlign}}>{dialog.content}</p>
                </div>


                <div className="d-flex justify-content-center mt-2 mb-2">
                    <Button variant={"outline-secondary"} className="btn-rounded" onClick={handleClose}>
                        {dialog.cancelText || intl.formatMessage({ id: "i00009" })}
                    </Button>
                    {dialog.customText && (
                        <Button variant={"outline-secondary"} onClick={onClickCustomBtn} className="ms-1">
                            {dialog.customText}
                        </Button>
                    )}
                    <Button
                        className={classNames("btn-rounded ms-1", { "d-none": dialog.hideConfirm })}
                        variant={dialog.variant}
                        onClick={onClickConfirmBtn}
                    >
                        {dialog.confirmText || intl.formatMessage({ id: "i00010" })}
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
});

export default useDialog;