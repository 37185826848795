import React, {Suspense, useMemo} from 'react';
import {useRecoilValue} from "recoil";
import i18nState from "./recoil/i18n";
import {DictType} from "@42maru/content-editor/dist/custom-types";
import ko from "./i18n/ko";
import en from "./i18n/en";
import {IntlProvider} from "react-intl";
import {ErrorBoundary} from "react-error-boundary";
import ErrorFallback from "./component/ErrorFallback";
import styled from "styled-components";
import {Route, Routes} from "react-router-dom";
import * as views from './view'
import {NotificationContainer} from "./hook/useNotification";
import {Dialog} from "./hook/useDialog";
import FullAreaSpinner from "./component/FullAreaSpinner";
import {Toaster} from "react-hot-toast";


const AppWrapper = () => {
  const i18n = useRecoilValue(i18nState)
  const messages: DictType<Record<string, string>> = useMemo(() => {
    return {"ko": ko, "en": en};
  }, []);

  return (
      <IntlProvider locale={i18n} messages={messages[i18n]}>
        <App/>
      </IntlProvider>
  )
};

const App: React.FC = () => {
  return (
          <ErrorBoundary FallbackComponent={ErrorFallback}>
              <Suspense fallback={<FullAreaSpinner/>}>
                  <AppStyle>
                      {/*<Menu/>*/}
                      <div className='flex-grow-1 d-flex'>
                          <Routes>
                              <Route path={''} element={<views.knowledgeGraphTemplateListView/>}/>
                              <Route path={'knowledge-graph-template/:templateId/intent'} element={<views.intentSelectView/>}/>
                              <Route path={'knowledge-graph-template/:templateId/skill/:externalSkillId'} element={<views.knowledgeTemplateEditView/>}/>
                          </Routes>
                      </div>
                  </AppStyle>
                  {/*<AuthProvider>*/}
                  {/*</AuthProvider>*/}
                  <Toaster position="top-right"/>
                  <NotificationContainer/>
                  <Dialog/>
              </Suspense>
          </ErrorBoundary>
  );
}

const AppStyle = styled.div`
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
`;

export default AppWrapper;
