const language: Record<string, string> = {
    i00001: '챗봇',
    i00002: "방문하시려는 페이지는 권한이 있는 사용자만 사용할 수 있습니다.\n채널 관리자에게 권한을 요청하시거나 권한이 있어도 같은 오류가 발생한다면 고객센터에 문의하여 주시기 바랍니다.",
    i00003: "방문하시려는 페이지의 주소가 잘못 입력되었거나, 페이지의 주소가 변경 혹은 삭제되어 요청하신 페이지를 찾을 수 없습니다.\n입력하신 주소가 정확한지 다시 한번 확인해 주시기 바랍니다.",
    i00004: "페이지를 찾을 수 없습니다.",
    i00005: "접근 권한이 없습니다.",
    i00006: "서비스 이용에 불편을 드려 죄송합니다.\n시스템 에러가 발생하여 페이지를 표시할 수 없습니다. 고객센터에 문의하시거나 잠시 후 다시 시도해 주세요.",
    i00007: "로그인",
    i00008: "로그인이 필요합니다.",
    i00009: "취소",
    i00010: "등록",
    i00011: "알림",
    i00012: "지식그래프",
    i00013: "로그아웃",
    i00014: "지식 그래프 템플릿 목록",
    i00015: "이름을 입력해주세요",
    i00016: "지식그래프 생성",
    i00017: "선택",
    i00018: "등록성공",
    i00019: "등록실패",
    i00020: "이름",
    i00021: "이름은 필수값입니다.",
    i00022: "선택된 Agent",
    i00023: "Agent 를 선택해주세요.",
    i00024: "생성하기",
    i00025: "연결할 Agent 를 선택하세요.",
    i00026: "Agent 이름을 입력해주세요",
    i00027: "선택하기",
    i00028: "지식 그래프 생성",
    i00029: "챗봇 템플릿과 연동하기",
    i00030: "선택된 지식그래프 템플릿",
    i00031: "선택된 챗봇 템플릿",
    i00032: "{name}의 다른 자식 그래프 템플릿과 연동을 해제하고, 선택된 지식그래프템플릿과 연동됩니다",
    i00033: "챗봇 템플릿을 선택하지 않으셨습니다.",



    i00100: "지식 관리 도구 이동",
    i00101: "인텐트 목록이 변경되었습니다.\n저장하지 않고 지식 관리 도구로 이동하시겠습니까?",
    i00102: "이동",
    i00103: "인텐트 저장 성공",
    i00104: "인텐트 저장 실패",
    i00105: "Home",
    i00106: "인텐트 및 스킬 목록",
    i00107: "인텐트 목록 비어있음",
    i00108: "스킬 목록 비어있음",
    i00109: "인텐트 저장하기",
    i00110: "지식 템플릿 수정",
    i00111: "인텐트를 선택해주세요",
    i00112: "설명을 입력하세요",
    i00113: "설명 저장하기",
    i00114: "설명 저장 중입니다",
    i00115: "설명 저장 성공",
    i00116: "설명 저장 실패",
    i00117: "등록된 템플릿 답변",
    i00118: "템플릿 답변 작성",
    i00119: "템플릿 답변 제목을 입력하세요",
    i00120: "답변을 입력하세요",
    i00121: "답변 저장하기",
    i00122: "템플릿 답변 저장 성공",
    i00123: "템플릿 답변 저장 실패",
    i00124: "템플릿 답변을 참고해 답변을 완성하세요",
    i00125: "인텐트 이동",
    i00126: "템플릿 답변이 저장되지 않았습니다. \n 저장하지 않고 인텐트를 이동하시겠습니까?",
    i00127: "이동",
    i00128: "설명을 입력해주세요",
    i00129: "제목을 입력해주세요",
    i00130: "편집",
    i00131: "삭제",
    i00132: "템플릿 답변 삭제 성공",
    i00133: "템플릿 답변 삭제 실패",
    i00134: "답변 업데이트",

    i10001: "파일을 먼저 등록해주세요.",
    i10002: "템플릿 답변 업로드 작업이 등록되었습니다.",
    i10003: "템플릿 답변 업로드 작업 등록 실패",
    i10004: "템플릿 답변 일괄 등록 중입니다.",
    i10005: "템플릿 답변 등록이 완료되었습니다.",
    i10006: "템플릿 답변 등록 중 오류가 발생했습니다.",
    i10007: "시스템 오류 발생",
    i10008: "템플릿 답변 엑셀 파일 등록",
    i10009: "템플릿 답변에 대한 양식을 다운받아 내용을 채워 업로드해주세요.",
    i10010: "양식 받기",
    i10011: "파일 업로드",
    i10012: "여기를 클릭해 파일을 업로드하세요.",
    i10013: "취소",
    i10014: "등록",
    i10015: "Import 기능을 통해서 템플릿 답변을 한 번에 등록할 수 있습니다. " +
            "양식 받기 버튼을 클릭하여 업로드할 파일의 양식을 확인할 수 있습니다. " +
            "양식 파일은 다음의 두 시트로 이루어져 있습니다. Export 기능으로 받은 파일도 동일합니다.",
    i10016: "1. Intent Description - 인텐트의 ID, 이름에 알맞는 설명을 적어주세요.",
    i10017: "2. Template Answers - 인텐트에 알맞는 템플릿 답변을 공백 없이 적어주세요. 현재는 텍스트만 지원합니다. (줄바꿈 허용)",
    i10018: "주의사항",
    i10019: "1. 인텐트 ID와 이름이 매칭되어야 합니다. 가급적 양식을 통해서 얻은 ID와 이름을 그대로 사용해주세요.",
    i10020: "2. 인텐트 설명은 비어있을 수 없습니다. 비어있다면 실패 케이스로 기록됩니다.",
    i10021: "3. 템플릿 답변은 Template Content 1~4에 작성하게 되는데, 연속적으로 작성되어야 하며 빈 칸이 있으면 실패 케이스로 기록됩니다.",
    i10022: "Import 결과 확인",
    i10023: "템플릿 파일 등록 작업은 백그라운드에서 실행되기 때문에 완료되기까지 시간이 소요될 수 있습니다. " +
            "작업이 완료되면 자동으로 히스토리에서 확인할 수 있습니다. (너무 오래 걸리지는 않아요!) " +
            "결과 파일에서 실패 케이스를 확인하시고 실패 원인이 이해되지 않는다면 저(우즈)한테 문의 부탁드립니다.",
    i10024: "Export 버튼",
    i10025: "Export 기능을 통해서 현재 지식그래프 템플릿에 적용되어 있는 템플릿 답변을 엑셀 파일로 받아볼 수 있습니다. " +
            "선택 후 저장하지 않은 인텐트는 엑셀 파일 내용에서 제외됩니다. " +
            "엑셀 파일은 다음의 두 시트로 이루어져 있습니다. 이 형식은 Import 기능에서도 그대로 사용할 수 있습니다.",
    i10026: "1. Intent Description - 현재 활성화된 인텐트의 ID, 이름, 설명이 적혀있습니다.",
    i10027: "2. Template Answers - 현재 활성화된 인텐트의 템플릿 답변들이 적혀있습니다.",
    i10028: "Import 버튼",
    i10029: "템플릿 파일 관련 도움말",
    i10030: "히스토리를 삭제하는 도중 오류가 발생했습니다.",
    i10031: "템플릿 답변 업로드 히스토리",
    i10032: "No",
    i10033: "업로드 파일",
    i10034: "업로드 결과",
    i10035: "소요 시간",
    i10036: "업로드 시각",
    i10037: "결과 요약 (전체/성공/실패)",
    i10038: "삭제",
    i10039: "업로드한 파일 받기",
    i10040: "업로드 실패",
    i10041: "결과 파일 받기",
    i10042: "Intent_Description: ",
    i10043: "Template_Answers: ",
    i10044: "템플릿 답변 초기화",
    i10045: "이 인텐트에 등록되어 있는 모든 템플릿 답변은 삭제합니다. 이 작업은 되돌릴 수 없습니다.",
    i10046: "등록된 템플릿 답변을 모두 초기화했습니다.",
    i10047: "템플릿 답변을 초기화하는 도중 오류가 발생했습니다.",
    i10048: "초기화",
    i10049: "템플릿 파일 관련 도움말",
    i10050: "템플릿 답변 파일 다운로드 히스토리",
    i10051: "히스토리를 삭제하는 도중 오류가 발생했습니다.",
    i10052: "양식 히스토리 보기",
    i10053: "다운로드한 파일",
    i10054: "다운로드한 시각",
    i10055: "4. 템플릿 답변에 볼드체를 반영하고 싶으시면 <strong></strong>으로 감싸주세요. 감싸진 영역은 자동으로 볼드 처리되어 답변이 등록됩니다. " +
            "아직은 엑셀에서 에디터를 통해 볼드 처리한 문자를 인식하지 못하기 때문에 번거롭더라도 이 방법으로 볼드체 처리를 부탁드립니다.",
    i10056: "실패 케이스 설명",
    i10057: "- ERROR_IntentId",
    i10058: "- ERROR_IntentName",
    i10059: "- ERROR_IntentDescription",
    i10060: "- ERROR_TemplateTitle",
    i10061: "- ERROR_TemplateContent",
    i10062: "  인텐트 ID를 찾지 못했습니다. 양식 받기를 통해서 받은 파일에서 인텐트 ID를 수정하지는 않았는지 확인해보세요.",
    i10063: "  인텐트 ID와 인텐트 이름이 동일하지 않습니다. 양식 받기를 통해서 받은 파일을 확인해보세요.",
    i10064: "  인텐트 설명이 비어있습니다. 설명을 채워주세요.",
    i10065: "  템플릿 답변 제목이 비어있습니다. 제목을 채워주세요.",
    i10066: "  템플릿 답변에 공백이 포함되어 있습니다. 공백을 제외하고 입력해주세요.",
    i10067: "이 지식그래프 템플릿에 포함되어 있는 모든 템플릿 답변이 삭제됩니다.\n이 작업은 되돌릴 수 없습니다.",

};


export default language;