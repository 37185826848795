import {Spinner} from "react-bootstrap";
import React from "react";


const FullAreaSpinner = () => (
    <div className="d-flex align-items-center justify-content-center h-100 w-100">
        <Spinner variant="info" animation={'grow'} className="m-2" />
    </div>
)

export default FullAreaSpinner