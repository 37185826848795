import React, {useMemo} from "react";
import {AxiosError} from "axios";
import View40X from "./error/View40X";
import {useIntl} from "react-intl";
import View500 from "./error/View500";
import View401 from "./error/View401";

const ErrorFallback: React.FC<{error: Error, resetErrorBoundary: (...args: Array<unknown>) => void}> = ({error}) => {
    const intl = useIntl();
    // const location = useLocation();

    const axiosError = useMemo(() => {
        if ('isAxiosError' in error) {
            const axiosError = error as AxiosError
            if (axiosError.isAxiosError) {
                return axiosError;
            }
        }

        return null;
    }, [error]);

    const status = useMemo(() => {
        if (axiosError) {
            return axiosError.response?.status;
        }
        return null;
    }, [axiosError]);

    // const onClickReset = (e: React.MouseEvent) => {
    //     e.preventDefault();
    //
    //     resetErrorBoundary();
    // }

    if (status === 401) {
        return (
            <View401 code={status} title={intl.formatMessage({id: 'i00007'})} description={intl.formatMessage({id: 'i00008'})}/>
        )

    }
    else if (status === 403) {
        return (
            <View40X code={status} title={intl.formatMessage({id: 'i00005'})} description={intl.formatMessage({id: 'i00002'})}/>
        );
    }
    else if (status === 404) {
        return (
            <View40X code={status} title={intl.formatMessage({id: 'i00004'})} description={intl.formatMessage({id: 'i00003'})}/>
        );
    }
    else {
        return (
            <View500 />
        );
    }
}

export default ErrorFallback;